table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

td:last-child, th:last-child {
    border-right: 2px solid black; 
    padding-right: 15px;
}
