@mixin breakpoint($point) {
	$small: 767px; // Up to 767px
    $medium: 1279px; // Up to 1279px
    $large: 1439px; // Up to 1439px
    $extra-large: 1800px; // Up to 1800px
	@if $point == extra-large {
		@media only screen and (min-width : $large+1) { @content; }
    }
    @else if $point == large {
		@media only screen and (min-width : $medium+1) and (max-width: $large) { @content; }
	}
	@else if $point == medium-large {
		@media only screen and (min-width: $medium+1) { @content; }
	}
	@else if $point == medium {
		@media only screen and (min-width: $small+1) and (max-width: $medium) { @content; }
	}
	@else if $point == small-medium {
		@media only screen and (max-width: $medium) { @content; }
	}
	@else if $point == small {
		@media only screen and (max-width: $small) { @content; }
	}
}

@mixin clearfix {
	*zoom: 1;
	&:before, &:after {
		display: table;
		content: "";
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}