$white: #ffffff;
$blue: #0095D3;
$darkgrey: #333333;
$grey: #777777;
$lightgrey: #F2F2F2;
$darkblue: #002538;
$purple: #7F35B2;
$black: #111111;
$mainblue: #0091DA;
$lightblue: #00C1D5;
$navyblue: #1D428A;