@import 'variables';
@import 'mixins';
@import 'base';

footer {
    .top-links {
        min-height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $lightgrey;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }
    .right-links {
        padding: 0px;
        li {
            img {
                vertical-align: bottom;
                margin-right: 10px;
            }
            a {
                color: $darkgrey;
                font-weight: 300;
                font-size: 12px;
                font-family: $metropolis-light;
            }
        }
        .mobile {
            display: none;
        }
    }
    .left-links {
        p {
            margin: 0px;
        }
        .copywrite {
            font-size: 12px;
            padding-right: 10px;
            a {
                font-size: 12px;
                color: $darkgrey;
                font-family: $metropolis-light;
            }
        }
        a {
            vertical-align: middle;
        }
        img {
            height: 46px;
            width: auto;
            margin-right: 20px;
        }
    }
    .bottom-links {
        width: 60%;
        small {
            font-size: 12px;
            vertical-align: middle;
        }
        p {
            line-height: 16px;
        }
        a {
            font-size: 12px;
            font-family: $metropolis-light;
            vertical-align: middle;
        }
        img {
            height: 30px;
            width: auto;
            margin-right: 20px;
        }
    }
    @include breakpoint(small) {
        .footer-links {
            display: block;
            .right-links {
                display: none;
            }
            .left-links {
                float: none;
                margin: 10px 0px;
                .desktop {
                    display: none;
                }
                .mobile {
                    display: inline;
                }
                .copywrite {
                    display: block;
                    margin-top:20px;
                }
            }
        }
        .bottom-links {
            margin: 10px 0px 20px 0px;
            width: 100%;
            img {
                margin-left: 0px;
                display: block;
                margin-top: 10px;
            }
        }
    }
}